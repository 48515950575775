import { template as template_a901ff57e8d942f1995c80cbb6b228a9 } from "@ember/template-compiler";
const FKText = template_a901ff57e8d942f1995c80cbb6b228a9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
