import { template as template_ae83cb3da873438ab954ad5342d6bc43 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_ae83cb3da873438ab954ad5342d6bc43(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
