import { template as template_09bd60b446594e898b92cbeae2ae13a2 } from "@ember/template-compiler";
const FKLabel = template_09bd60b446594e898b92cbeae2ae13a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
