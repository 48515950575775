import { template as template_20d0ccc4b4b64e6e81081a4b6b02cbda } from "@ember/template-compiler";
const FKControlMenuContainer = template_20d0ccc4b4b64e6e81081a4b6b02cbda(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
